/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-05-26 20:33:52
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-06-08 16:26:17
 * @FilePath: /java_pc_o2o/src/utils/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 *  项目的配置文件
 */
export const chatUrl = 'wss://im.longfffff.com';//客服地址
export const mUrl = 'https://m.longfffff.com/'//移动端网页地址
export const apiUrl = 'https://www.longfffff.com/';//接口请求地址

export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const title = 'slodon';//浏览器顶部title
export const gdKey = 'abf281e81230281a8e3b306b44659f17';//高德web-js key
export const statShowDebug = false;//是否开启统计的调试

/** copyright *** slodon *** version-v4.0 *** date-2022-05-26 ***主版本v4.0**/



